import React from "react"
import Header from "../../components/header"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import Footer from "../../components/footer"
import ContactBox from "../../components/boxContact"

import imgFassade01 from "../../assets/leistungen/fassade01.jpg"
import imgFassade02 from "../../assets/leistungen/fassade02.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung
      Title="Fassaden und Fassadenbekleidung"
      Content="
        „Du hast keine zweite Chance, einen ersten Eindruck zu hinterlassen.“
        <p />
        Was für den Menschen gilt, kann auch eins zu eins auf ein Gebäude übertragen werden. Deshalb ist die Fassade ein wichtiges Gestaltungselement für den positiven Eindruck eines Gebäudes, dessen Wirkung von der Wahl der verwendeten Materialien, Formate und Konstruktionssysteme beeinflusst wird. Weitere Faktoren sind natürlich auch Anordnung, Größe und Gliederung von Fenster- und Türelementen – dazu kann noch das Zusammenspiel von Licht und Schatten auf der Fassade kommen, was einen ganz besonderen Reiz ausübt."
    />
    <LeistungBox
      Color="gray"
      Image={imgFassade01}
      IsImageLeft={true}
      Title="Der perfekte Ersteindruck Ihres Hauses"
      Content="Ob stilvoll und elegant, geradlinig und dezent, verspielt und lebendig, repräsentativ oder zurückhaltend – egal, welche Eigenschaften auf Ihr Gebäude zutreffen, die Wahl der Materialien spielt eine entscheidende Rolle. Fachmännische Fassadenbekleidung gehört zu unserer täglichen Arbeit – das schafft Erfahrung. Die braucht man, wenn es um die Auswahl der richtigen Materialien für die Fassadengestaltung geht. Fragen Sie uns, wir kommen gerne bei Ihnen vorbei. Mit geschultem Blick können wir Ihnen dann dabei helfen, die für Sie optimale Wahl unter den zur Verfügung stehenden Materialien (wie z. B. Zink, Aluminium, Naturschiefer, spezielle Fassadenplatten etc.), aber auch Formaten, Farben und Verlegemustern zu treffen."
    />
    <LeistungBox
      Color="white"
      Image={imgFassade02}
      IsImageLeft={false}
      Title="Heizkosten sparen!"
      Content="Bis zu 80 % der Energiekosten bei privaten Haushalten sind Heizkosten. Eine bessere Wärmedämmung, z. B. der Fassade, kann diese Bilanz entscheidend verbessern. Denn durch die Schichtenfolge der Bekleidung wird die Außenwand wie durch eine zusätzliche Schale gegen Wind und Wetter, Hitze und Kälte geschützt.
        <p />
        Eine Außenwandbekleidung trägt aber nicht nur zur Energieeinsparung und zu einem wohligen Raumgefühl bei, sie setzt auch optische Glanzpunkte. Als Fachbetrieb bieten wir Ihnen eine große Auswahl an Materialien (Zink, Aluminium, Naturschiefer, spezielle Fassadenplatten etc.), Formaten, Farben und Verlegemustern an.
        <p />
        Wir gestalten die Fassade nach Ihren persönlichen Vorstellungen und schenken Ihrem Anwesen somit ein attraktives Gesicht."
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
